<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Country List
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>

    <v-card flat class="custom-grey-border remove-border-radius mt-4">
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="4" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Country List
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="8" class="text-right py-0">
              <v-btn
                small
                class="text-white"
                color="cyan"
                v-on:click="updateStatus"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <v-card-text
        class="p-6 font-size-16"
        :class="{ 'pointer-events-none': !getPermission('setting:update') }"
      >
        <v-row>
          <v-col md="12" class="pb-4">
            <ListingTable
              :columnCount="4"
              :dataLoading="pageLoading"
              :rowData="countryList"
            >
              <template v-slot:thead>
                <thead>
                  <tr>
                    <th class="simple-table-th">#</th>
                    <th class="simple-table-th">Country</th>
                    <th class="simple-table-th">Status</th>
                    <th class="simple-table-th">Character Limit</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody class="custom-border-bottom">
                  <template v-if="countryList.length">
                    <tr v-for="(type, index) in countryList" :key="index">
                      <td class="simple-table-td">{{ index + 1 }}.</td>
                      <td class="simple-table-td">
                        <template>
                          <div
                            :class="type.country_code.toLowerCase()"
                            class="vti__flag mr-2"
                            style="float: left; margin-top: 3px"
                          />
                        </template>
                        <template>
                          <span
                            >{{ type.country_name }}
                            {{ `+${type.phone_code}` }}</span
                          >
                        </template>
                      </td>
                      <td class="simple-table-td">
                        <v-switch
                          class="my-2 p-0"
                          color="cyan"
                          v-model="type.status"
                          inset
                          hide-details
                          :disabled="statusLoading"
                        ></v-switch>
                      </td>
                      <td class="simple-table-td">
                        <v-text-field
                          dense
                          filled
                          id="phone_character_limit"
                          color="cyan"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-mask="'####'"
                          :rules="[
                            validateRules.minLength(
                              type.phone_character_limit,
                              'Character Limit',
                              1
                            ),
                            validateRules.maxLength(
                              type.phone_character_limit,
                              'Character Limit',
                              100
                            ),
                          ]"
                          v-model.trim="type.phone_character_limit"
                          label="Character Limit"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td :colspan="6" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no country at the moment.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </ListingTable>

            <table class="width-100 mt-4" v-if="false">
              <tbody>
                <tr v-for="(type, index) in countryList" :key="index">
                  <td>
                    <!-- <v-text-field
                        v-model.trim="type.name"
                        dense
                        filled
                        label="Job Type"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field> -->
                    <template>
                      <div
                        :class="type.iso2.toLowerCase()"
                        class="vti__flag mr-2"
                        style="float: left; margin-top: 3px"
                      />
                    </template>
                    <template>
                      <span>{{ type.name }} {{ `+${type.dialCode}` }}</span>
                    </template>
                  </td>
                  <td
                    class="cursor-default simple-table-td text-center"
                    align="center"
                  >
                    <v-switch
                      class="my-2 p-0"
                      color="cyan"
                      v-model="type.activated"
                      inset
                      hide-details
                      :disabled="statusLoading"
                      v-on:change="updateStatus(type.id)"
                    ></v-switch>
                  </td>
                  <td width="50">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on:click="deleteJobType(index)"
                          color="deep-orange"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <span>Click here to delete</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { GET, POST } from "@/core/services/store/request.module";
import allCountries from "@/core/plugins/country-list";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";

import LocalService from "@/core/services/local.service";

export default {
  mixins: [ValidationMixin],
  name: "profile-job-setting",
  data() {
    return {
      statusLoading: false,
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        buffer_time: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
        job_type: [],
      },
      countryList: allCountries,
    };
  },
  methods: {
    getCountryList() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/get-country" })
        .then(({ data }) => {
          _this.countryList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateStatus() {
      const _this = this;
      _this.statusLoading = true;
      _this.$store
        .dispatch(POST, {
          url: `setting/country`,
          data: { country_list: this.countryList },
        })
        .then(({ data }) => {
          const { all_countries, activeCountries } = data;
          _this.countryList = all_countries;

          LocalService.saveData("phone_countries", activeCountries);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.statusLoading = false;
        });
    },
  },
  components: {
    ListingTable,
  },
  mounted() {
    this.getCountryList();
  },
};
</script>
