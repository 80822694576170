<template>
  <v-container
    fluid
    class="width-80 setting-calender-tab"
    v-if="getPermission('setting:view')"
  >
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Calendar
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4 py-2">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                <v-layout class="align-center">
                  <p class="m-0">Holidays</p>
                  <v-select
                    v-model="year"
                    :items="yearList"
                    label="Year"
                    solo
                    flat
                    dense
                    filled
                    hide-details
                    item-color="cyan"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    class="px-4"
                    v-on:change="getHolidays"
                  ></v-select>
                </v-layout>
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0 my-auto">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="holidayDialog = true"
                >
                  <span class="font-size-16 font-weight-600">Create</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <div class="custom-listing-table">
            <v-simple-table
              class="simple-table"
              fixed-header
              :height="holidays.length > 0 ? 'auto' : '100px'"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="simple-table-th" width="50">#</th>
                    <th class="simple-table-th" width="100">Date</th>
                    <th class="simple-table-th" width="100">Type</th>
                    <th class="simple-table-th" width="100">Working</th>
                    <th class="simple-table-th">Description</th>
                  </tr>
                </thead>
                <tbody v-if="holidays.length > 0">
                  <tr
                    v-for="(holiday, index) in holidays"
                    :key="index"
                    v-on:click="updateHoliday(holiday)"
                  >
                    <td width="50" class="simple-table-td">{{ index + 1 }}</td>
                    <td width="100" class="simple-table-td">
                      {{ formatDate(holiday.date) }}
                    </td>
                    <td width="100" class="simple-table-td">
                      <template v-if="holiday.type == 1">Full Day</template>
                      <template v-else-if="holiday.type == 2"
                        >Half Day</template
                      >
                      <template v-else> - </template>
                    </td>
                    <td width="100" class="simple-table-td">
                      <template v-if="holiday.working">Yes</template>
                      <template v-else>No</template>
                    </td>
                    <td class="simple-table-td custom-nowrap-ellipsis-two-line">
                      {{ holiday.description }}
                    </td>
                  </tr>
                </tbody>
                <tfoot v-else>
                  <tr v-if="holidayLoading">
                    <td
                      class="simple-table-td"
                      v-for="(di, did) in 5"
                      :key="did"
                    >
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton"
                      ></v-skeleton-loader>
                    </td>
                  </tr>
                  <tr v-else>
                    <td colspan="5" class="simple-table-td">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no holiday at the moment.
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
    <Dialog :commonDialog="holidayDialog">
      <template v-slot:title>
        <template v-if="holidayData.id">Update </template>
        <template v-else>Create </template>Holiday
      </template>
      <template v-slot:body>
        <v-container fluid>
          <v-row>
            <v-col md="12">
              <DatePicker
                :pageLoading="pageLoading"
                solo
                :defaultDate="holidayData.date"
                :placeholder="'Date'"
                v-on:update:date-picker="setHolidayDate"
              ></DatePicker>
            </v-col>
            <v-col md="12">
              <v-textarea
                v-model.trim="holidayData.description"
                auto-grow
                dense
                filled
                :disabled="pageLoading"
                color="cyan"
                label="Description"
                solo
                flat
                row-height="25"
                hide-details
              ></v-textarea>
            </v-col>
            <v-col md="6">
              <v-radio-group
                :disabled="pageLoading"
                mandatory
                hide-details
                class="mt-0"
                v-model="holidayData.type"
              >
                <v-radio label="Full Day" color="cyan" :value="1"></v-radio>
                <v-radio label="Half Day" color="cyan" :value="2"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col md="6">
              <v-radio-group
                :disabled="pageLoading"
                mandatory
                hide-details
                class="mt-0"
                v-model="holidayData.working"
              >
                <v-radio label="Working" color="cyan" :value="1"></v-radio>
                <v-radio label="Non Working" color="cyan" :value="0"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :loading="createHolidayLoading"
          :disabled="pageLoading || createHolidayLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="CreateHoliday"
        >
          Save
        </v-btn>
        <v-btn
          :disabled="pageLoading || createHolidayLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="holidayDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import moment from "moment-timezone";
import { QUERY, POST } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      createHolidayLoading: false,
      holidayDialog: false,
      pageLoading: false,
      holidayLoading: false,
      year: moment().format("YYYY"),
      holidays: [],
      holidayData: {
        date: new Date().toISOString().substr(0, 10),
        description: null,
        type: 1,
        working: 1,
      },
      yearList: [
        {
          value: moment().subtract(2, "year").format("YYYY"),
          text: moment().subtract(2, "year").format("YYYY"),
        },
        {
          value: moment().subtract(1, "year").format("YYYY"),
          text: moment().subtract(1, "year").format("YYYY"),
        },
        {
          value: moment().format("YYYY"),
          text: moment().format("YYYY"),
        },
        {
          value: moment().add(1, "year").format("YYYY"),
          text: moment().add(1, "year").format("YYYY"),
        },
        {
          value: moment().add(2, "year").format("YYYY"),
          text: moment().add(2, "year").format("YYYY"),
        },
      ],
    };
  },
  watch: {
    holidayDialog() {
      this.holidayData = {
        date: new Date().toISOString().substr(0, 10),
        description: null,
        type: 1,
        working: 1,
      };
    },
  },
  components: {
    Dialog,
    DatePicker,
  },
  methods: {
    CreateHoliday() {
      const _this = this;
      _this.createHolidayLoading = true;
      _this.$store
        .dispatch(POST, { url: "holiday", data: _this.holidayData })
        .then(() => {
          _this.getHolidays();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.createHolidayLoading = false;
        });
    },
    setHolidayDate(param) {
      this.holidayData.date = param;
    },
    updateHoliday(param) {
      const _this = this;
      _this.holidayDialog = true;
      setTimeout(function () {
        _this.holidayData = {
          id: param.id,
          date: param.date,
          description: param.description,
          type: param.type,
          working: param.working ? 1 : 0,
        };
      }, 500);
    },
    getHolidays() {
      const _this = this;
      _this.holidayLoading = true;
      _this.$store
        .dispatch(QUERY, { url: "holiday", data: { year: _this.year } })
        .then(({ data }) => {
          _this.holidays = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.holidayLoading = false;
          _this.holidayDialog = false;
        });
    },
  },
  mounted() {
    this.getHolidays();
  },
};
</script>
