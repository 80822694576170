<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Leave
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Leaves #
              </h3>
            </v-col>
            <template>
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white mr-5"
                  color="cyan"
                  v-on:click="AddLeave"
                >
                  <span class="font-size-16 font-weight-600">Add</span>
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateOrcreate"
                >
                  <span class="font-size-16 font-weight-600">Save</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row v-for="(type, index) in leaveList" :key="index">
            <v-col md="5" class="pb-4">
              <label>Category</label>
              <v-text-field
                v-model.trim="type.category"
                dense
                filled
                label="Category"
                solo
                flat
                hide-details
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="5" class="pb-4">
              <label>Max Days</label>
              <v-text-field
                v-model.trim="type.maxdays"
                dense
                filled
                label="Max Days"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="2" class="pb-4 mt-6">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-on:click="deleteCommon(type.id, index)"
                    color="deep-orange"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Click here to delete</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { POST, DELETE, GET } from "@/core/services/store/request.module";

export default {
  name: "leave-setting",
  data() {
    return {
      pageLoading: true,
      leaveList: [
        {
          category: null,
          maxdays: null,
        },
      ],
    };
  },
  methods: {
    updateOrcreate() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "setting/leave",
          data: { categories: _this.leaveList },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    AddLeave() {
      this.leaveList.push({
        text: null,
        number: null,
      });
    },
    deleteCommon(categoriesid, index) {
      if (categoriesid) {
        this.deleteSettingLeave(categoriesid, index);
      } else {
        this.deleteLeaveDay(index);
      }
    },
    deleteLeaveDay(index) {
      if (this.leaveList.length > 1) {
        this.leaveList.splice(index, 1);
      }
    },
    deleteSettingLeave(categoriesid, index) {
      const _this = this;
      _this.$store
        .dispatch(DELETE, {
          url: "setting/leave/" + categoriesid,
        })
        .then(() => {
          if (_this.leaveList.length > 0) {
            _this.leaveList.splice(index, 1);
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getLeaveDays() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "setting/leave",
        })
        .then(({ data }) => {
          _this.leaveList = data;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  /* getStaffSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/engineer" })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateStaffSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/engineer", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },*/
  mounted() {
    this.getLeaveDays();
  },
};
</script>
